<div class="page-header1" style="linear-gradient:(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(../images/cover-3.jpg) no-repeat center">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2 class="display-4" style="color: aliceblue;" translate>UN1060</h2>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h2 translate>UN1060</h2>
                            <p translate>UN1061</p>
                            <p translate>UN1062</p>
                            <p translate>UN1063</p>
                            <p translate>UN1064</p>
                            <p translate>UN1065</p>
                            <p translate>UN1066</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>